import './BreakScreen.css'
import { breakMessages} from '../config.js'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Form from "react-bootstrap/Form";
import LogoAndEaseMenu from './LogoAndEaseMenu.js';




import React, { useEffect, useRef, useState } from 'react';

export default function BreakScreen({backToLogin, OnContinue, breakError})
{
  const inputRef = useRef();
  const rand = useRef(Math.floor((Math.random() * 135)));

  useEffect(() => 
  {
    // specific selectors.
    var svg = document.querySelector('.timer-wrapper').querySelector('svg');
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%'); 
    document.body.style.overflow = 'hidden';

    return () => document.body.style.overflow = 'auto';

  }, []);
  
  const [timersCount, settimerCount] = useState(0);
    useEffect(() => {
        if(timersCount>=2)
          backToLogin();
      },  [timersCount]);
    function handleSubmit(password)
    {
        inputRef.current.value="";
        OnContinue(password);
    }
    
const renderTime = ({ remainingTime }) => {
  if (remainingTime<=2) {
    return <div className="timer">{timersCount===1 ? "Logging Out" : "Logout in 3 min"  }</div>;
  }


  return (
    <div className="timer">
      <div className="value">{remainingTime}</div>
    </div>
  );
};
// Todo fa eye default is bad in dark mode.
function doNothing(){

}
  return (
    <div className='BreakScreen Displayshow '>
      <div className="LogoInBreakScreen">
      <LogoAndEaseMenu OnClickFunction={doNothing} onClickEaseMenu={doNothing} isIconizedMenu={true}/>
    </div>
    <div className="OtherThanBreakScreenLogo">
        <div className='BreakScreenMsg'>
        {breakMessages[rand.current]}
        </div>
        <div className='OtherThanBreakScreenMsgAndLogo'>
        {timersCount<2 ? <div className="timer-wrapper">
        <CountdownCircleTimer size='100'
          isPlaying
          duration={180}
          colors={["#4cbf6a", "#4cbf6a", "#4cbf6a", "#4cbf6a"]}
          colorsTime={[180, 60, 20, 0]}
          onComplete={() => {
            settimerCount(timersCount+1);
            return { shouldRepeat: true, delay: 0 };
          }} 
        >
        
          {renderTime}
          </CountdownCircleTimer>

        </div> : <div/>}
        {breakError && <div className="BreakError ErrorDisplayBreakScreen">{breakError}</div>}
        <form className="BreakContinue" onSubmit={(e) => {e.preventDefault(); handleSubmit(inputRef.current.value);}}>
            <Form.Control type="password" placeholder='password' className='form-control breakScreenPwd' ref={inputRef}/>
            <button className="submitArrow" tabindex="0">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                <path d="M12 0c-6.623 0-12 5.377-12 12s5.377 12 12 12 12-5.377 12-12-5.377-12-12-12zm0 1c-6.071 0-11 4.929-11 11s4.929 11 11 11 11-4.929 11-11-4.929-11-11-11zm4.828 11.5l-4.608 3.763.679.737 6.101-5-6.112-5-.666.753 4.604 3.747h-11.826v1h11.828z"/>
              </svg>
            </button>
        </form>
    </div>
    </div>
    </div>
  )
}
