import React, { useEffect, useState } from 'react';
import "./IconizedMenu.css"
import {localhost} from '../config.js'
import LogoAndEaseMenu, {Logo} from './LogoAndEaseMenu.js';
/*
Icons are fetched from public bucket and it should be svgs only.
Todo:: all the info should be from Db.
 */
const IconizedMenu = ({subjects, passselectedsub}) => {
  const exclusiveIcons = subjects.filter(icon => icon.NeedsSubscription);
  const nonExclusiveIcons = subjects.filter(icon => !icon.NeedsSubscription);
  const [svgMap, setSvgMap] = useState(new Map());
  const [vh, setVh] = useState(window.innerHeight);

    // Handler to call on window resize
    // Safari bug of 100vh fix via JS.
    function handleResize() {
      // Set window's viewport height to state
      setVh(window.innerHeight);
    }
  useEffect(() => {
    // Bind the event listener
    window.addEventListener('resize', handleResize);

    // Update vh value initially
    handleResize();
    const fetchSvgs = async () => {
      const newMap = new Map();
      for (const subject of exclusiveIcons) {
        let text = sessionStorage.getItem(subject.SubjectName + '.svg');
        if (!text){
        const response = await fetch(localhost + subject.SubjectName + '.svg');
        text = await response.text();
        sessionStorage.setItem(subject.SubjectName + '.svg', text);
      }
        newMap.set(subject.SubjectName + '.svg', text);
      }
      for (const subject of nonExclusiveIcons) {
        let text = sessionStorage.getItem(subject.SubjectName + '.svg');
        if (!text){
        const response = await fetch(localhost + subject.SubjectName + '.svg');
        text = await response.text();
        sessionStorage.setItem(subject.SubjectName + '.svg', text);
      }
        newMap.set(subject.SubjectName + '.svg', text);
      }
      setSvgMap(newMap);
    };
    fetchSvgs();
    document.body.style.overflow = 'hidden';


    return () => {
    document.body.style.overflow = 'auto';
    window.removeEventListener('resize', handleResize);
    }

  }, []);

  // Use vh in your styles
  const AlldevicefixforStyle = {
    height: `${vh}px`,
  };

  return (
    <div className="wrapperIconizedMenuWithLogoAndEaseMenu" style={AlldevicefixforStyle}>
    <div className="IconizedMenuWithLogoAndEaseMenu">
    <div className='IconizedMenu'>
    <div className="icon-list">
      <div className="non-exclusive-icons">
        <h2 className='open-heading'>Free Resources</h2>
        <div className="non-exclusive-icon-buttons">
          {nonExclusiveIcons.map(subject => (
            <button className='non-exclusive-button' key={subject.DisplayName} onClick={() => passselectedsub(subject)}>
              {svgMap && <div className="svgIcons" dangerouslySetInnerHTML={{ __html: svgMap.get(subject.SubjectName + '.svg') }} />}
              <span className='IconTitle'>{subject.DisplayName}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="exclusive-icons">
        <h2 className='ex-heading'>Premium Resources</h2>
        <div className="exclusivemainicons">
          <div className='exclusive-icon-buttons'>
            {exclusiveIcons.length>0 ? exclusiveIcons.filter((subject) => !subject.DirectContent).sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)).map(subject => (
              <button className='exclusive-button' key={subject.DisplayName} onClick={() => passselectedsub(subject)}>
                {svgMap && <div className="svgIcons" dangerouslySetInnerHTML={{ __html: svgMap.get(subject.SubjectName + '.svg') }} />}
                <span className='IconTitle'>{subject.DisplayName}</span>
              </button>
            )) : <div class="text-stroke">COMING SOON</div>
            }
            </div>
            <div className='otherexclusivemainicons exclusive-icon-buttons'>
            {exclusiveIcons.length>0 ? exclusiveIcons.filter((subject) => subject.DirectContent).sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)).map(subject => (
              <button className='exclusive-button directContent' key={subject.DisplayName} onClick={() => passselectedsub(subject)}>
                {svgMap && <div className="svgIcons" dangerouslySetInnerHTML={{ __html: svgMap.get(subject.SubjectName + '.svg') }} />}
                <span className='IconTitle'>{subject.DisplayName}</span>
              </button>
            )) : <div class="text-stroke">COMING SOON</div>
            }
            </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default IconizedMenu;