import React, { useState, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import {LoginUrl, RegisterUrl, ResetPasswordUrl, ApiKey, Peptalk, WhatWeOffer, OurPhilosophy, ContactUsBody, TermsAndCondtions, PrivacyPolicyBody} from '../config';
import axios from 'axios';
import {LogowithTitle} from '../config.js'
import ModalDiag from "./ModalDiag";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ShowcaseSlider from './SliderSlide.js'
import "./Login.css";




export default function Login({ OnSuccess}) {
  // todo:: make all these state objects one state Json object
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isSignIn, setIsSignIn] = useState(1);
  const [mobilenumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [errormsg, setErrormsg] = useState({msg: "", isError: 1});
  const [isLoading, setLoading] = useState(false);
  const scrollError = useRef(null);
  const [ModalDiagState, setModalDiagState] = useState(null);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);


  
  function validMobileNumber(mobilenumber)
  {
    return mobilenumber && mobilenumber.toString().length ===10 && mobilenumber>6000000000
  }
  // Todo:: error display.
  // todo:: recaptcha on login only after domain name registration.
  function validateForm() {
    const mandatefieldsvalid = validMail(email) && password.length >= 7; 
    return isSignIn ? mandatefieldsvalid : (mandatefieldsvalid &&  validMobileNumber(mobilenumber) && name.length >0 && email===email2 && password===password2 && isAgreementChecked);
  }

 
  function validMail(email) {
    // Changes to this function means Server side also needs the similar change.
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }
  function setMsgFlash(error, iserror=1)
  {
    // Todo:: non error messages should be green in color.
    scrollError.current.scrollIntoView({ inline: 'center', behavior: 'smooth', block: 'nearest' });
    
    setErrormsg({msg: error, isError: iserror});
    setTimeout(() => {
      setErrormsg({msg: "", isError: 1});
    }, 3000);
  }
  //todo:: verify Mobile OTP
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    var req = {
      email: email.toLowerCase(),
      password: password
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    if(!isSignIn)
      {
        req['name'] = name;
        req['mobilenumber'] = mobilenumber;
        axios.post(RegisterUrl, req, { headers:headers}).then(resp=>{setLoading(false); setIsSignIn(true) ; setMsgFlash('Registration Successful. Please Sign In', 0)}).catch(err => {setLoading(false); setMsgFlash(err.response.data.message);});
      }
    else{
      // todo:: "Access-Control-Allow-Origin": "https://www.example.com" 
      axios.post(LoginUrl, req, { withCredentials: true, headers:headers}).then(
        resp=>{setLoading(false); OnSuccess(req.email)}).catch(err => {
          setLoading(false); 
          setMsgFlash(err.response.data.message);
        });
    }
  };
function ResetPassword()
  {
    var req = {
      email: email.toLowerCase(),
      loginUrl : window.location.href
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    axios.post(ResetPasswordUrl, req, { headers:headers}).then(resp=>{setMsgFlash('Mail Sent!, Please check your mailbox', 0)}).catch(err => { setMsgFlash(err.response.data.message);});
  }
function CloseModal()
{
  setModalDiagState(null);
}

var passwordRulesSet =  {
  "one lowercase character" : /[a-z]/,
  "one uppercase character" : /[A-Z]/,
  "one number"              : /[0-9]/,
  "one special character"   : /[^a-z0-9]/i,
  "8 characters minimum"    : /.{8,}/
};
const validatePassword = (passwordtest) => {
  // Check the minimum length requirement
  if (passwordtest.length < 8) {
    return "Password must be at least 8 characters long.";
  }

  // Check for at least one uppercase letter
  if (!/[A-Z]/.test(passwordtest)) {
    return "Password must contain at least one uppercase letter.";
  }

  // Check for at least one lowercase letter
  if (!/[a-z]/.test(passwordtest)) {
    return "Password must contain at least one lowercase letter.";
  }

  // Check for at least one numeric digit
  if (!/[0-9]/.test(passwordtest)) {
    return "Password must contain at least one numeric digit.";
  }

  // Check for at least one special character
  if (!/[!@#$%^&*()|}{}":;'><.,]/.test(passwordtest)) {
    return "Password must contain at least one special character (!, @, #, etc).";
  }

  // All requirements have been met
  return "";
}
const checkValidation = (rule, value) => {
  return new RegExp(rule).test(value);
};
useEffect(() =>
  {
    const elements = document.getElementsByClassName("passwordRules");
    if(elements.length===0)
      return;
    var ruleselem = elements[0].getElementsByTagName("li");
    for (let i = 0; i < ruleselem.length; i++) {
      const isValid = checkValidation(passwordRulesSet[ruleselem[i].innerText.toLowerCase()], password);
      ruleselem[i].classList.toggle('pass', isValid);
  }
},[password]);



function scrollToTargetAdjusted(classname){
  document.getElementsByClassName(classname)[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
 }
  return (
    <div className='WholeLoginPage'>
    {ModalDiagState && <ModalDiag title={ModalDiagState.title} body={ModalDiagState.Body} closeModal = {CloseModal} onOkay={CloseModal}/>}
    <div className="LoginLogowithBackground">
    <div className="LoginUpperPageMenu">
          <LogowithTitle />
      </div>
    <div className="LoginPotrait"></div>
    <div className="Login">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <form className="CCform" onSubmit={handleSubmit}>
      <div className='Formgroupr'>
        <Form.Group className="Formgroup">
        <div ref={scrollError} className={errormsg.msg.length===0 ? "ErrorDisplay" : (errormsg.isError===1 ? "ErrorDisplay ErrorDisplayAnimate" : "ErrorDisplayNoError")}>
          {errormsg.msg}
         </div>
         <div className="LoginHeaderTabs">
          <h2 className={isSignIn ? "active heading2" : "inactive underlineHover heading2"} onClick={() => {setIsSignIn(true);}}> Sign In </h2>
          <h2 className={!isSignIn ? "active heading2" : "inactive underlineHover heading2"} onClick={() => {setIsSignIn(false);}} >Sign Up </h2>
        </div>
        <div className="formControlGroup">
        {!isSignIn && <Form.Control placeholder="Name"
            type="name"
            value={name}
            tabindex="0"
            autoComplete="on"
            onChange={(e) => { setName(e.target.value);}}
          />}
          <Form.Control placeholder="Email ID"
            autoComplete="on"
            tabindex="0"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => {if(e.target.value==="") return; if(!validMail(e.target.value)){ setMsgFlash("Invalid Email ID"); e.target.value=""; setEmail(e.target.value);}}}
          />
           {!isSignIn && <Form.Control placeholder="Confirm Email ID"
            tabindex="0"
            disabled={email.length===0 ? true : false}
            onChange={(e) => setEmail2(e.target.value)}
            onBlur={(e) => {if(e.target.value!==email){ setMsgFlash("Mismatch in Email"); e.target.value=""; setEmail2(e.target.value);}}}
          />}
          {!isSignIn && <Form.Control placeholder="Mobile Number(Ind)"
            type="number"
            autoComplete="off"
            tabindex="0"
            value={mobilenumber}
            onChange={(e) => {if(!isNaN(mobilenumber)) setMobileNumber(e.target.value);}}
            onBlur={(e) => {e.preventDefault(); if(!validMobileNumber(e.target.value)){ setMsgFlash("Invalid indian mobile number"); e.target.value=""; setMobileNumber(e.target.value);}}}
            onWheel={(e) => {e.preventDefault(); e.target.blur();}}
          />}
          <div className="passwordInputWithRules">
            <Form.Control placeholder="Password"
              type="password"
              autoComplete="on"
              tabindex="0"
              onChange={(e) => {setPassword(e.target.value)}} 
              onBlur={(e) => {!isSignIn && setMsgFlash(validatePassword(e.target.value))}}
              onWheel={(e) => {e.preventDefault(); ; e.target.blur();}}
            />
            {!isSignIn &&
            <ul className='passwordRules'>
                <li>One lowercase character</li>
                <li>One uppercase character</li>
                <li>One number</li>
                <li>One special character</li>
                <li>8 characters minimum</li>
             </ul>
            }
          </div>
          {!isSignIn && <Form.Control placeholder="Confirm Password"
            tabindex="0"
            type="password"
            disabled={password.length===0 ? true : false}
            onChange={(e) => {setPassword2(e.target.value);}}
            onBlur={(e) => {if(e.target.value!==password){ setMsgFlash("Mismatch in passwords"); e.target.value=""; setPassword2(e.target.value);}}}

          />}
        {!isSignIn && <div className="Agreements">
            <input type="checkbox"  checked={isAgreementChecked} onChange={(event)=> { setIsAgreementChecked(event.target.checked);}}/>
              <label className="AgreementLabel">
              I agree to 
              <div className="ClickableModalDiag" onClick={()=> {setModalDiagState({title: "Privacy Policy", Body: PrivacyPolicyBody})}}>Privacy Policy, </div>
              <div className="ClickableModalDiag" onClick={()=> {setModalDiagState({title: "Terms And Conditions", Body: TermsAndCondtions})}}>Terms & Conditions </div> 
              of this website.
            </label>
        </div>
        }
        <button className="LoginBtn" type="submit" disabled={isLoading ||!validateForm()} ><div className="LoginBtnWithSpinner">{isLoading && <i className="fa fa-spinner fa-spin"></i>}{isSignIn ? "Login" : "Sign Up"}</div></button>
        {isSignIn && validMail(email) && <div className="ResetPassword" onClick={()=> ResetPassword()}>Reset Password</div>}
        </div>
        </Form.Group>
        </div>
      </form>
    </div>
    </div>
    <div className="Slider">
      <ShowcaseSlider />
    </div>
    <div className="AboutUsWhatWeOffer">
      <div className="AboutUs">
        <div className="AboutUsHeading">
          ABOUT US
        </div>
        <div className="AboutUsText" dangerouslySetInnerHTML={{__html: Peptalk}}>
        </div>
      </div>
      <div className="WhatWeOffer">
      <div className="WhatWeOfferHeading">
        WHAT WE OFFER
      </div>
        <div className = "WhatWeOfferText"  dangerouslySetInnerHTML={{__html: WhatWeOffer}}>
        </div>
      </div>
    </div>
    <div className="OurPhilosophyUnit">
    <div className="OurPhilosophy">
      <div className="OurPhilosophyHeading">
        OUR PHILOSOPHY
      </div>
      <div className="OurPhilosophyText" dangerouslySetInnerHTML={{__html: OurPhilosophy}}>
      </div>
    </div>
    </div>
    <div className="OthersMenu">
      <button className="ReachOutToUs" onClick={()=> {setModalDiagState({title: "Contact Us", Body: ContactUsBody})}}>Reach Out To Us</button>
      <button className="PrivacyPolicy" onClick={()=> {setModalDiagState({title: "Privacy Policy", Body: PrivacyPolicyBody})}}>Privacy Policy</button>
      <button className="TermsAndConditions" onClick={()=> {setModalDiagState({title: "Terms And Conditions", Body: TermsAndCondtions})}}>Terms and Conditions</button>
    </div>
    </div>
  );
}